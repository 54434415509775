import type { PartialRecordError } from '@/types/Form';
import type { Validation, ValidationArgs } from '@vuelidate/core';

function truncate(str: string, n = 75, ellipsis = '...'): string {
  return str.length > n ? `${str.substr(0, n - 1)}${ellipsis}` : str;
}

function capitalize(str: string): string {
  return str ? str.toString().charAt(0).toUpperCase() + str.slice(1) : '';
}

function capitalizeFirstChar(str: string) {
  return str.replace(/^./, (match) => match.toUpperCase());
}

function capitalizeSentence(str: string): string {
  const sentence = str.split(' ');

  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = capitalize(sentence[i]);
  }

  return sentence.join(' ');
}

function sentence(arr: any, conjunction = 'and'): string {
  if (Array.isArray(arr)) {
    const tmpSentence = [...arr];
    if (tmpSentence.length <= 2) {
      return tmpSentence.join(` ${conjunction} `);
    }

    const last = tmpSentence.pop();
    return tmpSentence.join(', ') + ` ${conjunction} ` + last;
  }

  return arr;
}

function parseToSnakeCase(str: string): string {
  return str ? str.replace(/\s+/g, '_').toLowerCase() : '';
}

function parseToFormData(data: any, _formData?: FormData, parentKey?: any): FormData {
  let formData = _formData || new FormData();
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    /**
     * NOTE: Special case scenario for `File upload` objects
     * where we want to pass the file
     *
     * TODO: Better checker for it? Not just `file` var
     */
    if (data.file) {
      formData.append(parentKey, data.file);
    } else {
      Object.keys(data).forEach((key) => {
        formData = parseToFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key);
      });
    }
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }

  return formData;
}

function isObject(value: any): boolean {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

function convertToSlug(str: string): string {
  return str
    .toLowerCase() // LowerCase
    .replace(/\s+/g, '-') // space to -
    .replace(/&/g, '-and-') // & to and
    .replace(/--/g, '-'); // -- to -
}

function numberToDecimal(value: number): number {
  if (!value) return 0;
  return Number((value / 100).toFixed(2));
}

const apiErrorTransformer = <T>(errors: Partial<Record<keyof T, string[]>>): Record<keyof T, string> => {
  return Object.keys(errors).reduce(
    (prev, curr) => {
      if (errors[curr as keyof T] !== undefined) {
        prev[curr as keyof T] = errors[curr as keyof T]?.[0] || '';
      }

      return prev;
    },
    {} as Record<keyof T, string>
  );
};

const parsedError = <T>(
  form: T,
  validator: globalThis.Ref<Validation<ValidationArgs<Partial<T>>, T>>,
  otherErrors?: PartialRecordError<T>
) => {
  return Object.keys(form as object).reduce(
    (prev, curr) => {
      const otherError = (otherErrors && otherErrors[curr as keyof T]) || '';

      prev[curr as keyof T] = otherError || validator.value[curr]?.$errors[0]?.$message;

      return prev;
    },
    {} as Record<keyof T, string | undefined>
  );
};

const formatCurrency = (value: number | string, currency = 'USD', customUserLang = null): string => {
  const userLang = navigator.language || navigator.userLanguage;
  return new Intl.NumberFormat(customUserLang ?? userLang, {
    style: 'currency',
    currency,
  }).format(value);
};

const hasNoEmptyProperty = (obj: any): boolean | void => {
  if (!isObject(obj)) return;

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const anArray = Array.isArray(obj[prop]);
      if (anArray && !obj[prop].length) {
        return false;
      } else if (!anArray && !obj[prop]) {
        return false;
      }
    }
  }

  return true;
};

const getUrlParam = (request: any, key: string) => {
  const fullUrl = new URL(useRuntimeConfig().public.apiURL + request);
  const queryParams = new URLSearchParams(fullUrl.search);
  const param = queryParams.get(key);

  return param;
};

export {
  apiErrorTransformer,
  capitalize,
  capitalizeFirstChar,
  capitalizeSentence,
  convertToSlug,
  formatCurrency,
  getUrlParam,
  hasNoEmptyProperty,
  isObject,
  numberToDecimal,
  parsedError,
  parseToFormData,
  parseToSnakeCase,
  sentence,
  truncate,
};
