import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
  if (useRuntimeConfig().public.recaptchaSiteKey) {
    const currentPage = useRoute().path;
    const autoHideBadge = currentPage.includes('/login') || currentPage.includes('/register') ? false : true;

    nuxtApp.vueApp.use(VueReCaptcha, {
      siteKey: useRuntimeConfig().public.recaptchaSiteKey,
      loaderOptions: {
        autoHideBadge,
        explicitRenderParameters: {
          badge: 'bottomright',
        },
      },
    });
  }
});
