import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.$config.public.sentryDSN) {
    Sentry.init({
      environment: nuxtApp.$config.public.sentryENV || 'production',
      app: nuxtApp.vueApp,
      dsn: nuxtApp.$config.public.sentryDSN,
      integrations: [
        Sentry.browserTracingIntegration({
          router: nuxtApp.vueApp.$nuxt.$router,
        }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
});
