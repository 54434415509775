<script setup lang="ts">
useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#317EFB',
    },
  ],
});
</script>
<template>
  <v-app>
    <NuxtRouteAnnouncer>
      <template #default="{ message }">
        <p>{{ message }} was loaded.</p>
      </template>
    </NuxtRouteAnnouncer>
    <!-- <VitePwaManifest /> -->
    <NuxtLoadingIndicator color="#313A68" :height="3" />
    <NuxtLayout />
  </v-app>
</template>
