import useSnackbarStore from '@/store/snackbar';
import { Model } from 'vue-api-query';

/**
 * api:
 * This function is used to handle fetching data from the server
 * It takes the request and the options for the fetch
 * It returns the data and the error
 *
 * Using $fetch to make network requests based on user interaction.
 *
 * @param {NitroFetchRequest} request: The request to fetch the data
 * @param {NitroFetchOptions} opts: The options for the fetch
 * @returns {pending, data, error, execute}
 */
export default defineNuxtPlugin(async () => {
  const snackbar = useSnackbarStore();
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');

  const $api = $fetch.create({
    baseURL: useRuntimeConfig().public.apiURL,
    headers: {
      Accept: 'application/json',
    },
    onRequest({ request, options, error }) {
      const noAuth = getUrlParam(request, 'X-NO-AUTH');
      const accessToken = useCookie(useRuntimeConfig().public.auth?.provider?.token?.cookieName, { watch: true });
      if (accessToken.value && !noAuth) {
        options.headers = new Headers(options.headers || {});
        options.headers.set('Authorization', `Bearer ${accessToken.value}`);
      }
    },
    async onResponseError({ response }) {
      let message = response._data.message;

      if (response._data?.message?.includes('No query results for model')) {
        message = 'No data found.';
      }

      snackbar.showSnackbar({
        text: message,
        state: false,
      });
    },
  });

  Model.$http = $api;

  return {
    provide: {
      api: $api,
    },
  };
});
