import validate from "/codebuild/output/src4176857051/src/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/codebuild/output/src4176857051/src/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  onboarding: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/middleware/onboarding.ts"),
  verify: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/middleware/verify.ts"),
  worker: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/middleware/worker.ts"),
  auth: () => import("/codebuild/output/src4176857051/src/repo/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}