import { default as aboutRyftPHaRvPMeta } from "/codebuild/output/src4176857051/src/repo/pages/account/about.vue?macro=true";
import { default as faqjaa740mP6xMeta } from "/codebuild/output/src4176857051/src/repo/pages/account/faq.vue?macro=true";
import { default as help_45centreouN3eHi8zhMeta } from "/codebuild/output/src4176857051/src/repo/pages/account/help-centre.vue?macro=true";
import { default as indexNz3NeGqDdkMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/index.vue?macro=true";
import { default as my_45orders5A1V8RimVkMeta } from "/codebuild/output/src4176857051/src/repo/pages/account/my-orders.vue?macro=true";
import { default as personal_45detailsOroUT2xZWxMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/personal-details.vue?macro=true";
import { default as delete_45accountaB8xRXAU2fMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/delete-account.vue?macro=true";
import { default as forget_45password2rxY1zq1huMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/forget-password.vue?macro=true";
import { default as indexNA1VD491k7Meta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/index.vue?macro=true";
import { default as update_45email_45addressrOfRJEqgeHMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/update-email-address.vue?macro=true";
import { default as update_45passwordPYaL3v7zdwMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/update-password.vue?macro=true";
import { default as contact_45uswPF8vXWIFJMeta } from "/codebuild/output/src4176857051/src/repo/pages/contact-us.vue?macro=true";
import { default as frankieone_45verificationDG7EqjzIVSMeta } from "/codebuild/output/src4176857051/src/repo/pages/frankieone-verification.vue?macro=true";
import { default as index1PQMfmtoPLMeta } from "/codebuild/output/src4176857051/src/repo/pages/index.vue?macro=true";
import { default as logind4nsS2kuN0Meta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/login.vue?macro=true";
import { default as logoutPLL76Xvmw6Meta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/logout.vue?macro=true";
import { default as notificationslfNBbM4If8Meta } from "/codebuild/output/src4176857051/src/repo/pages/notifications.vue?macro=true";
import { default as _91clientSlug_93HGhl98dlwKMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/[clientSlug].vue?macro=true";
import { default as disclaimers4gxKEG7aQMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/disclaimer.vue?macro=true";
import { default as id_45selectorIzQC4ou4KYMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/id-selector.vue?macro=true";
import { default as indexl9TE8tXoP7Meta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/index.vue?macro=true";
import { default as welcomez8lJwtC6iYMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/welcome.vue?macro=true";
import { default as privacy_45policy50eBTGE46TMeta } from "/codebuild/output/src4176857051/src/repo/pages/privacy-policy.vue?macro=true";
import { default as indexf58gcCk3P8Meta } from "/codebuild/output/src4176857051/src/repo/pages/profile/index.vue?macro=true";
import { default as educationG09XW3gJnRMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/education.vue?macro=true";
import { default as identity_45documentsgYlupy23IFMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/identity-documents.vue?macro=true";
import { default as indexg093sw7djwMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/index.vue?macro=true";
import { default as personal_45detailsr5pItZ42TuMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/personal-details.vue?macro=true";
import { default as _91document_930T1goLGU5NMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/required-documents/[document].vue?macro=true";
import { default as indexC8MreSzP4VMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/required-documents/index.vue?macro=true";
import { default as training_45and_45qualificationsAGC2vVFFPtMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/training-and-qualifications.vue?macro=true";
import { default as work_45experienceUz7lrU9eXqMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/work-experience.vue?macro=true";
import { default as your_45profileXVow63RoKqMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/your-profile.vue?macro=true";
import { default as availabilitiesdE8nlsQHoVMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/availabilities.vue?macro=true";
import { default as indexPw3jFLeSLrMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/index.vue?macro=true";
import { default as indicative_45ratesUxdjxwrqCFMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/indicative-rates.vue?macro=true";
import { default as service_45offeringsEIgzebs3XdMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/service-offerings.vue?macro=true";
import { default as support_45seeker_45preferencesC2aukj3LOAMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/support-seeker-preferences.vue?macro=true";
import { default as indexJg0NSk4AqgMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/index.vue?macro=true";
import { default as qualifications_45neededgOl2Z5d1yqMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/qualifications-needed.vue?macro=true";
import { default as required_45work_45hoursOAWlpBl6sDMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/required-work-hours.vue?macro=true";
import { default as support_45preferencesctWmuQRR25Meta } from "/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/support-preferences.vue?macro=true";
import { default as support_45requirementsOQB7O3VROkMeta } from "/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/support-requirements.vue?macro=true";
import { default as redirectbSiM79jOHeMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/redirect.vue?macro=true";
import { default as registerapRAWXfXnnMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/register.vue?macro=true";
import { default as requestsSzcgtvCcz6Meta } from "/codebuild/output/src4176857051/src/repo/pages/requests.vue?macro=true";
import { default as reset_45password4O12iSNhwYMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/reset-password.vue?macro=true";
import { default as index9QJeFwtoweMeta } from "/codebuild/output/src4176857051/src/repo/pages/search/index.vue?macro=true";
import { default as _91id_93Uaxij64N8lMeta } from "/codebuild/output/src4176857051/src/repo/pages/search/support-workers/[id].vue?macro=true";
import { default as showcase1gkKJPzE9SMeta } from "/codebuild/output/src4176857051/src/repo/pages/showcase.vue?macro=true";
import { default as signinbxD2dOEwBmMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/signin.vue?macro=true";
import { default as subscription2qantrO6aVMeta } from "/codebuild/output/src4176857051/src/repo/modules/subscription/pages/subscription.vue?macro=true";
import { default as terms_45of_45usesH446AbAQ1Meta } from "/codebuild/output/src4176857051/src/repo/pages/terms-of-use.vue?macro=true";
import { default as verifynfJXcTHeQxMeta } from "/codebuild/output/src4176857051/src/repo/modules/auth/pages/verify.vue?macro=true";
export default [
  {
    name: "account-about",
    path: "/account/about",
    meta: aboutRyftPHaRvPMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/account/about.vue").then(m => m.default || m)
  },
  {
    name: "account-faq",
    path: "/account/faq",
    meta: faqjaa740mP6xMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/account/faq.vue").then(m => m.default || m)
  },
  {
    name: "account-help-centre",
    path: "/account/help-centre",
    meta: help_45centreouN3eHi8zhMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/account/help-centre.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexNz3NeGqDdkMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-my-orders",
    path: "/account/my-orders",
    meta: my_45orders5A1V8RimVkMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/account/my-orders.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-details",
    path: "/account/personal-details",
    meta: personal_45detailsOroUT2xZWxMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-delete-account",
    path: "/account/settings/delete-account",
    meta: delete_45accountaB8xRXAU2fMeta || {},
    alias: ["/my-profile/account-settings/delete-account"],
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-forget-password",
    path: "/account/settings/forget-password",
    meta: forget_45password2rxY1zq1huMeta || {},
    alias: ["/my-profile/account-settings/forget-password"],
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: indexNA1VD491k7Meta || {},
    alias: ["/my-profile/account-settings"],
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-update-email-address",
    path: "/account/settings/update-email-address",
    meta: update_45email_45addressrOfRJEqgeHMeta || {},
    alias: ["/my-profile/account-settings/update-email-address"],
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/update-email-address.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-update-password",
    path: "/account/settings/update-password",
    meta: update_45passwordPYaL3v7zdwMeta || {},
    alias: ["/my-profile/account-settings/update-password"],
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/account/settings/update-password.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: contact_45uswPF8vXWIFJMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "frankieone-verification",
    path: "/frankieone-verification",
    meta: frankieone_45verificationDG7EqjzIVSMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/frankieone-verification.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index1PQMfmtoPLMeta || {},
    alias: ["/home"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logind4nsS2kuN0Meta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationslfNBbM4If8Meta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-clientSlug",
    path: "/onboarding/:clientSlug()",
    meta: _91clientSlug_93HGhl98dlwKMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/[clientSlug].vue").then(m => m.default || m)
  },
  {
    name: "onboarding-disclaimer",
    path: "/onboarding/disclaimer",
    meta: disclaimers4gxKEG7aQMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-id-selector",
    path: "/onboarding/id-selector",
    meta: id_45selectorIzQC4ou4KYMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/id-selector.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexl9TE8tXoP7Meta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-welcome",
    path: "/onboarding/welcome",
    meta: welcomez8lJwtC6iYMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/onboarding/welcome.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policy50eBTGE46TMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexf58gcCk3P8Meta || {},
    alias: ["/my-profile"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-education",
    path: "/profile/profile-information/education",
    meta: educationG09XW3gJnRMeta || {},
    alias: ["/my-profile/profile-info/education"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/education.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-identity-documents",
    path: "/profile/profile-information/identity-documents",
    meta: identity_45documentsgYlupy23IFMeta || {},
    alias: ["/my-profile/profile-info/identity-documents"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/identity-documents.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information",
    path: "/profile/profile-information",
    meta: indexg093sw7djwMeta || {},
    alias: ["/my-profile/profile-info"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-personal-details",
    path: "/profile/profile-information/personal-details",
    meta: personal_45detailsr5pItZ42TuMeta || {},
    alias: ["/my-profile/profile-info/personal-details"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-required-documents-document",
    path: "/profile/profile-information/required-documents/:document()",
    meta: _91document_930T1goLGU5NMeta || {},
    alias: ["/my-profile/profile-info/required-documents/:document()"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/required-documents/[document].vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-required-documents",
    path: "/profile/profile-information/required-documents",
    meta: indexC8MreSzP4VMeta || {},
    alias: ["/my-profile/profile-info/required-documents"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/required-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-training-and-qualifications",
    path: "/profile/profile-information/training-and-qualifications",
    meta: training_45and_45qualificationsAGC2vVFFPtMeta || {},
    alias: ["/my-profile/profile-info/training-and-qualifications"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/training-and-qualifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-work-experience",
    path: "/profile/profile-information/work-experience",
    meta: work_45experienceUz7lrU9eXqMeta || {},
    alias: ["/my-profile/profile-info/work-experience"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/work-experience.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-your-profile",
    path: "/profile/profile-information/your-profile",
    meta: your_45profileXVow63RoKqMeta || {},
    alias: ["/my-profile/profile-info/your-profile"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/profile-information/your-profile.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-availabilities",
    path: "/profile/services-offered/availabilities",
    meta: availabilitiesdE8nlsQHoVMeta || {},
    alias: ["/my-profile/services/availabilities"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/availabilities.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered",
    path: "/profile/services-offered",
    meta: indexPw3jFLeSLrMeta || {},
    alias: ["/my-profile/services"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-indicative-rates",
    path: "/profile/services-offered/indicative-rates",
    meta: indicative_45ratesUxdjxwrqCFMeta || {},
    alias: ["/my-profile/services/rates"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/indicative-rates.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-service-offerings",
    path: "/profile/services-offered/service-offerings",
    meta: service_45offeringsEIgzebs3XdMeta || {},
    alias: ["/my-profile/services/service-offerings"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/service-offerings.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-support-seeker-preferences",
    path: "/profile/services-offered/support-seeker-preferences",
    meta: support_45seeker_45preferencesC2aukj3LOAMeta || {},
    alias: ["/my-profile/services/preferences"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/services-offered/support-seeker-preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements",
    path: "/profile/support-requirements",
    meta: indexJg0NSk4AqgMeta || {},
    alias: ["/my-profile/support-reqs"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-qualifications-needed",
    path: "/profile/support-requirements/qualifications-needed",
    meta: qualifications_45neededgOl2Z5d1yqMeta || {},
    alias: ["/my-profile/support-reqs/qualifications-needed"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/qualifications-needed.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-required-work-hours",
    path: "/profile/support-requirements/required-work-hours",
    meta: required_45work_45hoursOAWlpBl6sDMeta || {},
    alias: ["/my-profile/support-reqs/required-work-hours"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/required-work-hours.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-support-preferences",
    path: "/profile/support-requirements/support-preferences",
    meta: support_45preferencesctWmuQRR25Meta || {},
    alias: ["/my-profile/support-reqs/support-preferences"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/support-preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-support-requirements",
    path: "/profile/support-requirements/support-requirements",
    meta: support_45requirementsOQB7O3VROkMeta || {},
    alias: ["/my-profile/support-reqs/support-requirements"],
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/profile/support-requirements/support-requirements.vue").then(m => m.default || m)
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirectbSiM79jOHeMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerapRAWXfXnnMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "requests",
    path: "/requests",
    meta: requestsSzcgtvCcz6Meta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/requests.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4O12iSNhwYMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: index9QJeFwtoweMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search-support-workers-id",
    path: "/search/support-workers/:id()",
    meta: _91id_93Uaxij64N8lMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/search/support-workers/[id].vue").then(m => m.default || m)
  },
  {
    name: "showcase",
    path: "/showcase",
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/showcase.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinbxD2dOEwBmMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: subscription2qantrO6aVMeta || {},
    alias: ["/my-profile/subscribe"],
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/subscription/pages/subscription.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45usesH446AbAQ1Meta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifynfJXcTHeQxMeta || {},
    component: () => import("/codebuild/output/src4176857051/src/repo/modules/auth/pages/verify.vue").then(m => m.default || m)
  }
]