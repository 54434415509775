import DayJSAdapter from '@date-io/dayjs';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import 'vuetify/styles';

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    date: {
      adapter: DayJSAdapter,
    },

    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        light: {
          colors: {
            'on-surface': '#000A25',
            background: '#FFFFFF',
            surface: '#FFFFFF',
            primary: '#6e16f4',
            'primary-1': '#6e16f4',
            'primary-2': '#E7EDFF',
            'primary-3': '#F3F6FF',
            success: '#7DDB25',
            warning: '#FFCE22',
            error: '#F10021',
            info: '#1135b3',
            disabled: '#CECECE',
            inactive: '#B2B5BE',
            tertiary: '#818094',
            'page-background': '#F6F6F6',
            '-primary': '#0A0A0A',
            '-secondary': '#474747',
            '-tertiary': '#848484',
          },
        },
      },
    },
    defaults: {
      global: {
        ripple: false,
      },
      VBtn: {
        rounded: 'lg',
        class: 'text-capitalize',
        flat: true,
      },
      VTextField: {
        class: 'bordered-input',
      },
    },
  });

  nuxtApp.vueApp.use(vuetify);
});
