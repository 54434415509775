import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';

export default defineNuxtPlugin(async (nuxtApp) => {
  dayjs.extend(relativeTime);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  nuxtApp.provide('dayjs', dayjs);
});

declare module '#app' {
  interface NuxtApp {
    $dayjs: dayjs.Dayjs;
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $dayjs(date?: dayjs.ConfigType): dayjs.Dayjs;
  }
}
